import { format, parse } from 'date-fns';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// UI Kit
import Button from 'ui-kit/button/button';

// Display Components
import {
    MembershipAddMemberAlreadyExistErrorModalContent,
    MembershipSettingsErrorModal
} from 'display-components/membership/modals';
import MembershipSendInvitation, { MemberToAdd } from 'display-components/membership/send-invitation';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

// States
import { accountProfilEPostPatientNumSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { membershipAddMemberRoutine, membershipSendInviteRoutine } from 'state/membership/membership.routines';
import { membershipMemberToInviteSelector } from 'state/membership/membership.selector';

// Types
import { MembershipAddedResponse } from 'types/membership';

// Utils
import { MEMBERSHIP_RESPONSE_ERRORS, PATIENT_ALREADY_HAS_CAREGIVER, PATIENT_ALREADY_INVITED } from 'util/membership';
import { convertToTitleCase } from 'util/string';

// Styles
import './membership-send-invite-page.style.scss';

export type SendInviteMembershipProps = {
    data: GatsbyTypes.SendInviteMembershipQuery;
};

type TreatedData = {
    firstName: string;
    lastName: string;
    age: string;
    dateOfBirth: string;
    prescriptionNumber?: string;
    insuranceID?: string;
    accountType: number;
    email?: string;
    phone?: string;
    ePostPatientNumber?: string;
};

type ModalProps = {
    name: string;
    onClick?: () => void;
};

type InvitationSentModalSendInviteMembershipProps = ModalProps;

const SuccessModalSendInviteMembership: FC<ModalProps> = (props) => {
    const { name, onClick: foreignOnClick } = props;
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const onClick = useCallback(() => {
        if (foreignOnClick) {
            foreignOnClick();
            return;
        }

        dispatch(closeModal({}));
    }, [dispatch, foreignOnClick]);

    return (
        <BirdiModalContent
            icon={'default'}
            title={t(`pages.profile.membership.sendInvite.modals.success.title`)}
            body={
                <div className="membership-send-invite__success-modal">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(t(`pages.profile.membership.sendInvite.modals.success.body`, { name }))
                        }}
                    ></div>
                    <Button
                        className="membership-send-invite__btn-modal"
                        type="button"
                        label={t('pages.profile.membership.sendInvite.modals.button')}
                        onClick={onClick}
                    />
                </div>
            }
        />
    );
};

const InvitationSentModalSendInviteMembership: FC<InvitationSentModalSendInviteMembershipProps> = (props) => {
    const { name, onClick: foreignOnClick } = props;
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const onClick = useCallback(() => {
        if (foreignOnClick) {
            foreignOnClick();
            return;
        }

        dispatch(closeModal({}));
    }, [dispatch, foreignOnClick]);

    return (
        <BirdiModalContent
            icon={'default'}
            title={t(`pages.profile.membership.sendInvite.modals.invitationSent.title`)}
            css="text-slate"
            body={
                <div className="membership-send-invite__modal">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                t(`pages.profile.membership.sendInvite.modals.invitationSent.body`, { name })
                            )
                        }}
                    ></div>
                    <Button
                        className="membership-send-invite__btn-modal"
                        type="button"
                        label={t('pages.profile.membership.sendInvite.modals.button')}
                        onClick={onClick}
                    />
                </div>
            }
        />
    );
};

export const SendInviteMembership: FC<SendInviteMembershipProps> = ({ data }) => {
    const profileEPostNum = useSelector(accountProfilEPostPatientNumSelector);
    const memberToInvite = useSelector(membershipMemberToInviteSelector);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isBusy, setIsBusy] = useState(false);
    const [memberToAdd, setMemberToAdd] = useState<Partial<TreatedData>>({});
    const [isMemberMinor, setIsMemberMinor] = useState<boolean>(false);

    const onClickModalHandler = () => {
        dispatch(closeModal({}));
        navigate('/secure/profile/membership');
    };
    const handleOnCancel = () => {
        navigate('/secure/profile/membership', { state: memberToAdd ?? undefined });
    };

    const closeModalHandler = () => {
        dispatch(closeModal({}));
    };

    useEffect(() => {
        if (memberToInvite) {
            const parsedDateOfBirth = parse(memberToInvite.dateOfBirth, 'M/d/yyyy', new Date());
            setMemberToAdd({
                firstName: memberToInvite.firstName,
                lastName: memberToInvite.lastName,
                age: String(memberToInvite.age),
                dateOfBirth: format(parsedDateOfBirth, 'MM/dd/yyyy'),
                accountType: memberToInvite.age ? (memberToInvite.age < 18 ? 1 : 2) : 0, // Minor / Adult only
                email: memberToInvite.emailAddress,
                phone: memberToInvite.mobilePhone,
                ePostPatientNumber: ''
            });
            setIsMemberMinor((memberToInvite.age && Number(memberToInvite.age) <= 12) as boolean);
        }
    }, [memberToInvite]);

    const showGenericErrorModal = useCallback(
        (memberName: string, variant: 'sendInvitation' | 'addMember' | 'sendInvitationAssociated') => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => dispatch(closeModal({})),
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('components.membershipModals.errorTitle')} icon="alert" />
                    ),
                    bodyContent: (
                        <MembershipSettingsErrorModal
                            variation={variant}
                            memberName={convertToTitleCase(memberName)}
                            t={t}
                            onClose={() => dispatch(closeModal({}))}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    const showAddMemberErrorModal = useCallback(
        (memberToAdd: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => setIsBusy(false),
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.profile.addFamilyMember.genericErrorModalTitle')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <MembershipSettingsErrorModal
                            variation="addNewMember"
                            memberName={convertToTitleCase(memberToAdd)}
                            t={t}
                            onClose={() => dispatch(closeModal({}))}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    const showMemberAlreadyAddedErrorModal = useCallback(
        (name: string, isCaregiverError: boolean) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => setIsBusy(false),
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.profile.addFamilyMember.AddMemberErrorTitle')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <MembershipAddMemberAlreadyExistErrorModalContent
                            name={name}
                            translation={t}
                            onContinueClick={() => {
                                setIsBusy(false);
                                dispatch(closeModal({}));
                            }}
                            isCaregiverError={isCaregiverError}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    const sendInvite = (response: MembershipAddedResponse, contactMethod?: string) => {
        const ModalComponent = isMemberMinor
            ? SuccessModalSendInviteMembership
            : InvitationSentModalSendInviteMembership;
        dispatch(
            membershipSendInviteRoutine.trigger({
                data: {
                    communicationType: contactMethod,
                    ePostPatientNumber: response.ePostPatientNumber || response.EPostPatientNumber,
                    holderEPostPatientNumber: profileEPostNum
                },
                onSuccess: () => {
                    setIsBusy(false);

                    dispatch(
                        openModal({
                            showClose: true,
                            onClose: closeModalHandler,
                            bodyContent: (
                                <ModalComponent name={String(response.firstName)} onClick={onClickModalHandler} />
                            ),
                            ctas: []
                        })
                    );
                },
                onFailure: () => {
                    setIsBusy(false);
                    showGenericErrorModal(response.firstName || '', 'sendInvitation');
                }
            })
        );
    };

    const handleOnSubmit = (contactMethod?: string) => {
        setIsBusy(true);

        dispatch(
            membershipAddMemberRoutine.trigger({
                data: memberToInvite,
                onSuccess: (response: MembershipAddedResponse) => {
                    sendInvite(response, contactMethod);
                },
                onFailure: (errors: MembershipAddedResponse) => {
                    setIsBusy(false);
                    if (
                        errors.messageText.includes(PATIENT_ALREADY_INVITED) ||
                        errors.messageText.includes(MEMBERSHIP_RESPONSE_ERRORS.PATIENT_HAS_ALREADY_PLAN)
                    ) {
                        showMemberAlreadyAddedErrorModal(memberToInvite?.firstName?.trim() as string, false);
                    } else if (errors.messageText.includes(PATIENT_ALREADY_HAS_CAREGIVER)) {
                        showMemberAlreadyAddedErrorModal(memberToInvite?.firstName?.trim() as string, true);
                    } else {
                        showAddMemberErrorModal(errors.firstName?.trim() as string);
                    }
                }
            })
        );
    };
    return (
        <div className="membership-send-invite__wrapper">
            <WorkflowLayout backgroundImage={data.feathersBackground} useRoundedCorners={false} anonymousOnly={false}>
                <WorkflowLayoutFormWrapper
                    eyebrowText={t('pages.profile.membership.sendInvite.eyebrowText')}
                    title={t('pages.profile.membership.sendInvite.title')}
                >
                    <MembershipSendInvitation
                        dependentData={memberToAdd as MemberToAdd}
                        isBusy={isBusy}
                        onSubmit={(contactMethod: string) => {
                            handleOnSubmit(contactMethod);
                        }}
                        onCancel={handleOnCancel}
                    />
                </WorkflowLayoutFormWrapper>
            </WorkflowLayout>
        </div>
    );
};

export default SendInviteMembership;

export const query = graphql`
    query SendInviteMembership($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        feathersBackground: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
